import React from 'react';

import 'styles/components/Loader.scss';

class Loader extends React.Component {
    render() {
        return (
            <div className="loader">
                <div></div><div></div>
            </div>
        );
    }
}

export default Loader;