import React, { useCallback, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Turnstone from 'turnstone';

import 'styles/components/Header.scss';

class Header extends React.Component<any, any> {
    render() {
        const { hideQuery = true } = this.props;
        return (
            <header>
                <div className="header-top"></div>
                <div className="header-main">
                    <h2><Link to={`/`}>Jota-Kaart</Link></h2>
                    <QueryComponent hideQuery={hideQuery}/>
                    <nav>
                        <p><Link to={`/`}>Dashboard</Link></p>
                        <p><Link to={`/map`}>Kaart</Link></p>
                        <p><Link to={`/tools`}>Tools</Link></p>
                        <p><Link to={`/docs`}>Docs</Link></p>
                    </nav>
                </div>
            </header>
        );
    }
}

// @ts-ignore
function QueryComponent(props) {
    const [hideQuery, sethideQuery] = React.useState({...props.hideQuery});
    const turnstoneRef = useRef();
    const navigate = useNavigate();

    React.useEffect(() => {
        sethideQuery(props.hideQuery);
    }, [props.hideQuery]);

    const queryData = {
        name: 'Groepen',
        data: (query: string) =>
            fetch(`http://vin-109/jota/api/v1/search/${encodeURIComponent(query)}`)
                .then(response => response.json()),
        searchType: 'contains'
    };

    const queryStyles = {
        container: 'query-container',
        listbox: 'query-listbox',
        input: 'query-input',
        typeahead: 'query-typeahead',
        item: 'query-item',
        highlightedItem: 'query-item-highlight',
    };

    // @ts-ignore
    const queryItem = ({ item }) => {
        return (
            <>
                <p>{item.guildName}<br />
                <span>{item.callsign} - {item.guildLocation} (Rayon {item.region})</span></p>
            </>
        );
    };

    const onSelect = useCallback(
        // @ts-ignore
        (item) => {
            if (item !== undefined) {
                // @ts-ignore
                turnstoneRef.current?.clear();
                navigate(`/map/${encodeURIComponent(item.callsign)}`);
            }
        }, [] //eslint-disable-line
    )

    return hideQuery ? null :<Turnstone
        id="query"
        listbox={queryData}
        styles={queryStyles}
        ref={turnstoneRef}
        onSelect={onSelect}
        maxItems={5}
        placeholder="Zoeken"
        noItemsMessage="Geen groepen gevonden"
        Item={queryItem} />;
}

export default Header;