import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import 'styles/App.scss'
import 'styles/_fonts.scss'
import Default from 'routes/Default';
import Error from 'routes/Error';
import Loader from 'components/Loader';

const Dashboard = React.lazy(() => import("routes/Dashboard"));
const Map = React.lazy(() => import("routes/Map"));
const Docs = React.lazy(() => import("routes/Docs"));

const router = createBrowserRouter([
    {
        path: '/',
        element: <Default hideQuery={false} />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Dashboard />,
            },
            {
                path: '/map',
                element: <Map />,
                children: [{
                    path: ':callsign',
                    element: <Map />,
                }]
            },
        ],
    },
    {
        path: '/docs/:title?',
        element: <Docs />,
        errorElement: <Error />,
    },
    {
        path: '/tools',
        element: <Error />,
        errorElement: <Error />,
        children: [
            {
                
            },
        ]
    },
]);

function App() {
    return (
        <React.Suspense fallback={<Loader />} >
            <RouterProvider router={router} />
        </React.Suspense>
    );
}

export default App;