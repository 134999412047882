import React from 'react';
import { Outlet } from "react-router-dom";

import Header from 'components/Header';
import Loader from 'components/Loader';

// @ts-ignore
function Layout(props) { 
    return (
        <>
            <Header hideQuery={props.hideQuery} />
            <main>
                <React.Suspense fallback={<Loader />} >
                    <Outlet />
                </React.Suspense>
            </main>
        </>
    );
}
export default Layout;