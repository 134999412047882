import React from 'react';
import { useRouteError } from 'react-router-dom';

import 'styles/routes/Error.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';

class Error extends React.Component {
    render() {
        return (
            <>
                <Header />
                <main className="errorPage">
                    <ErrorContainer />
                </main>
                <Footer />
            </>
        );
    }
}

function ErrorContainer() {
    var error = useRouteError();

    if (error) {
        /* @ts-ignore */
        switch(error.status) {
            case 400:
                error = { status: 400, statusText: 'Foute aanvraag' };
                break;
            case 401:
                error = { status: 401, statusText: 'Niet geautoriseerd' };
                break;
            case 403:
                error = { status: 403, statusText: 'Geen toegang' };
                break;
            case 404:
                error = { status: 404, statusText: 'Pagina niet gevonden' };
                break;
        }
    } else {
        error = { status: 404, statusText: 'Pagina niet gevonden' };
    }
    

    return (
        <div className="errorContainer">
            <h1>Oeps.</h1>
                
            {/* @ts-ignore */}
            { error.statusText && <p>{error.statusText}</p> }
            {/* @ts-ignore */}
            {error.status && <p>{error.status}</p> }
        </div>
    );
}

export default Error;