import React from 'react';
import { Link } from "react-router-dom";

import 'styles/components/Footer.scss';

class Header extends React.Component {
    render() {
        return (
            <footer>
                <hr />
                <p>© 2023 <Link to={`/about`}>Vincent Brokx</Link></p>
                <p>Jota-Kaart is op geen enkele wijze onderdeel van <a rel="noreferrer" target="_blank" href="https://www.scouting.nl/">Scouting Nederland</a> of <a rel="noreferrer" target="_blank" href="https://jota-joti.scouting.nl/">de Landelijke Jota-Joti Organisatie</a>.</p>
            </footer>
        );
    }
}

export default Header;